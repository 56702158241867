(function( $ ) {
    'use strict';

    var tiImplants = $( '.ti-implants-inner' );
    var tiImplantsBoxes = $( '.ti-implants-box' );
    var tiImplantsOrnament = $( '.ti-implants-ornament' );
    var tiImplantsOffset;
    var windowHeight = $( window ).height();
    var implantsTimeout = null;

    var checkPosition = function() {
        tiImplantsOffset = tiImplants.offset().top;
        var newScrollTop = $( window ).scrollTop();
        windowHeight = $( window ).height();
        console.log( tiImplantsOffset, windowHeight + newScrollTop );
        changePosition( tiImplantsOffset, windowHeight + newScrollTop );
        implantsTimeout = setTimeout( checkPosition, 80 );
    };

    var changePosition = function( offset, scrolled ) {
        var $elements = tiImplantsBoxes.add( tiImplantsOrnament );
        if ( offset < scrolled ) {
            $elements.addClass( 'active' );
        } else {
            $elements.removeClass( 'active' );
        }
    };

    $(function() {
        if ( tiImplants.length ) {
            checkPosition();
        }
    });

})( jQuery );
