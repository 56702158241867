(function( $ ) {

    var slider = $( '.ti-slider' );

    $(function() {
        slider.slick({
            dots: false,
            autoplay: true,
            autoplaySpeed: 6000,
            prevArrow: '<button type="button" class="slick-prev"><i class="icon ion-ios-arrow-back"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="icon ion-ios-arrow-back"></i></button>'
        });
        $( '.ti-hero.slick-slide' ).each(function() {
            var $tiHero = $( this );
            var imgUrl = $( '.ti-slide-img', $tiHero ).attr( 'src' );
            $tiHero.css( 'background-image', 'url(' + imgUrl +  ')' );
        });
    });

})( jQuery );
