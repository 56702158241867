(function( $ ) {
    'use strict';

    var jsNav = $( '.js-nav' );
    var jsBack = $( '.js-back' );
    var $body = $( 'body' );
    var lastScrollTop = 0;
    var scrollTimeout = null;

    var checkPosition = function() {
        var newScrollTop = $( window ).scrollTop();
        if ( lastScrollTop !== newScrollTop ) {
            if ( newScrollTop >= jsNav.height() ) {
                $body.css( 'padding-top', jsNav.height() );
                jsNav.addClass( 'is-sticky' );
                jsBack.addClass( 'is-active' );
            } else {
                $body.css( 'padding-top', 0 );
                jsNav.removeClass( 'is-sticky' );
                jsBack.removeClass( 'is-active' );
            }
            lastScrollTop = newScrollTop;
        }
        scrollTimeout = setTimeout( checkPosition, 80 );
    };

    var backToTop = function() {
        jsBack.on( 'click', function( e ) {
            e.preventDefault();
            $( 'body,html' ).animate({
                scrollTop: 0
            }, 700, 'swing' );
        });
    };

    $(function() {
        checkPosition();
        backToTop();
    });

})( jQuery );
