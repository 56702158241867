(function( $ ) {
    'use strict';

    var tiPublicationsSearch = $( '.ti-publication-search' );
    var tiPublicationsFilter = $( '.ti-publication-filter' );

    function closeSearch() {
        tiPublicationsSearch.toggleClass( 'ti-is-closed' );
        tiPublicationsFilter.toggleClass( 'ti-first-child' );
    }

    function activateBtn() {
        $( '.ti-publication-search-close' ).on( 'click', closeSearch );
    }

    $(function() {
        if ( tiPublicationsSearch.length ) {
            activateBtn();
        }
    });

})( jQuery );
