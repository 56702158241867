(function( $ ) {
    'use strict';

    var tiHero = $( '.ti-hero.ti-parallax' );
    var lastScrollTop = 0;
    var heroTimeout = null;

    var checkPosition = function() {
        var newScrollTop = $( window ).scrollTop();
        if ( lastScrollTop !== newScrollTop ) {
            changePosition( newScrollTop );
            lastScrollTop = newScrollTop;
        }
        heroTimeout = setTimeout( checkPosition, 80 );
    };

    var changePosition = function( newScrollTop ) {
        tiHero.css( 'background-position', '100% ' + ( 100 - newScrollTop / 2 ) + '%' );
    };

    $(function() {
        checkPosition();
    });

})( jQuery );
