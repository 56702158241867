(function( $ ) {

    var tabs = $( '.ti-tabbed' );

    function initTab( tab ) {

        //Add active class to first tab content
        $( '.ti-tabbed-section:eq(0)', tab ).addClass( 'ti-active' );

        //Activate links
        $( '.ti-tabbed-nav', tab ).on( 'click', function( e ) {
            e.preventDefault();
            changeTab( $( this ), tab );
        });
    }

    function changeTab( link, tab ) {
        if ( ! link.hasClass( 'ti-active' ) ) {
            $( '.ti-tabbed-nav', tab ).removeClass( 'ti-active' );
            link.addClass( 'ti-active' );
            $( '.ti-tabbed-section', tab ).removeClass( 'ti-active' );
            $( link.attr( 'href' ) ).addClass( 'ti-active' );
        }
    }

    $(function() {
        if ( tabs.length ) {
            tabs.each(function() {
                initTab( $( this ) );
            });
        }
    });

})( jQuery );
